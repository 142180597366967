<template>
  <BlokkliProvider
    v-slot="{ isEditing, entity }"
    v-bind="blokkliProps"
    :can-edit="!!blokkliProps?.canEdit && !isHuge"
    :entity="props"
  >
    <p
      v-if="blokkliProps?.canEdit && isHuge"
      class="font-bold p-20 bg-red-100 text-red-800 rounded"
    >
      Das Bearbeiten von Filterseiten über den Editor ist nur bis max. 100
      Einträgen möglich. Bitte benutzen Sie den Menüpunkt «Filterseiteneinträge»
      (neben «Bearbeiten») um die Einträge zu bearbeiten.
    </p>

    <ContentHeader
      :title="entity?.title || title"
      :lead="entity?.lead || lead"
    />

    <BlokkliField :list="paragraphs" name="field_paragraphs" />

    <Container v-if="isEditing" class="pb-30">
      <p class="font-bold p-20 bg-blue-50 text-blue-800 rounded my-20">
        Hinweis: Die Einträge werden beim Speichern automatisch alphabetisch
        sortiert.
      </p>
      <p class="font-bold p-20 bg-blue-50 text-blue-800 rounded my-20">
        Für Filterseiten steht die Vorschau nicht zur Verfügung.
      </p>
      <BlokkliField
        name="field_paragraphs_filter_items"
        class="grid"
        :class="
          listStyle === 'grid'
            ? 'md:grid-cols-3 grid-cols-1 gap-x-20 gap-y-40'
            : 'gap-10'
        "
      />
    </Container>

    <SearchPage
      v-if="id && !isEditing"
      v-slot="{ hit }"
      endpoint="filter-page-items"
      :query-params="{
        nodeId: id,
      }"
      :completion-context="{
        parentId: id,
      }"
      :size="48"
      :list-class="
        listStyle === 'grid'
          ? 'grid md:grid-cols-3 grid-cols-1 gap-x-20 gap-y-40'
          : 'grid gap-10'
      "
    >
      <FilterPageItem v-bind="hit" :list-style="listStyle">
        <!-- Paragraphs -->
        <BlokkliField
          :list="hit.paragraphs"
          name="field_paragraphs"
          field-list-type="nested"
        />
      </FilterPageItem>
    </SearchPage>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeFilterPageFragment } from '#graphql-operations'

const props = defineProps<{
  id?: string
  title?: string
  hasPendingIndexItems?: boolean
  lead?: string
  listStyle?: string
  paragraphs?: NodeFilterPageFragment['paragraphs']
  filterPageItems?: NodeFilterPageFragment['filterPageItems']
  blokkliProps: NodeFilterPageFragment['blokkliProps']
}>()

const isHuge = computed(() => (props.filterPageItems?.count || 0) > 100)

// Provide the selected terms and visible IDs to the child paragraphs.
provide('nodeFilterPageStyle', props.listStyle || 'list')

defineOptions({
  name: 'NodeFilterPage',
})

useCDNHeaders((v) => v.addTags(['nuxt:page:filter_page']))
</script>
